<template>
    <div>
        <Header />

        <div class="px-3">
            <div class="mb-4">
                <p class="text-white py-3 text-center">Password  Reset email will be sent to your registered email</p>

                <div class="border-bottom border-yellow-1 mb-4">
                    <p class="text-white mb-2">Email</p>
                    <div class="flex-between-center">
                        <div class="rounded-1 h-px-48 bg-white flex-between-center flex-grow-1">
                            <input type="text" class="form-control border-0 h-100" placeholder="email" v-model="email" >
                        </div>
                    </div>
                </div>

                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="SendCodeAgain()">
                    Send Password Reset Email
                </button>
            </div>

            
            <div class="text-white fs-px-12 pt-3">
                <p class="mb-3">If you did not receive the email, try the following:</p>
                <p><i class="fal fa-exclamation-triangle fs-px-11 me-1 mb-3"></i> Make sure to enter your registered email address</p>
                <p><i class="fal fa-exclamation-triangle fs-px-11 me-1 mb-3"></i> Make sure the email address you entered is correct</p>
                <p><i class="fal fa-exclamation-triangle fs-px-11 me-1"></i> Check spam or junk mail folders</p>
            </div>
        </div>
        <Loading v-if="loading"/>
    </div>
    
</template>

<script>
import Header from '@/components/common/Header.vue'

const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'

export default {
    components:{
        Header,Loading
    },
    data(){
        return{
            loading:false,
            email: '',
        }
    },
    methods:{
        SendCodeAgain(){
            const email = this.email;
            if( email == '' ){
                this.$pp({
                    msg: 'Please enter a valid email address', 
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }
            let regex = new RegExp('[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}');

            const test = regex.test(this.email);

            if(!test){
                this.$pp({
                    msg: 'Please check your email format', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return;
            }
            
            const body = {email};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/member/mailing/ResetPassword',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("Reset password verfication email sent!<br/>This account will be automatically logged out.").then(()=>{
                                
                                this.$store.dispatch('logout').then(
                                    ()=>{
                                        sessionStorage.setItem("toast_msg", "Reset password verfication email sent!");
                                        location.href = `/signin`;
                                    }
                                );
                            })
                            
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'This is an unregistered email.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            
                            return false;
                        }
                    }
                }   
            )
            
        },
    }
}
</script>
<style scoped>
    .code_box input{
        line-height: 48px;
    }
</style>