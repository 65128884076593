<template>
    <div>
        <Header :link="'/signin'"/>

        <!-- <div class="px-3">

            <p class="text-white fw-bold fs-px-18 mb-4 py-3">Reset Password</p>

            <div class="mb-4">
                <div class="rounded-1 h-px-48 bg-white flex-between-center mb-3">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/email.svg" alt="email" width="30">
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your email" v-model="email">
                </div>
            </div>

            <div class="mb-5">
                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="SendCode()">
                    Send verification code
                </button>
            </div>
        </div> -->
        <Loading v-if="loading"/>

        <b-modal v-model="resetPwModal" centered hideHeader body-class="pb-0" footer-class="pt-0" no-close-on-backdrop>
            <div>
                <h6 class="text-center mb-3 py-3">Reset Password</h6>
                
                <div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center border border-gray-300 mb-3">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                        </div>
                        <input :type="reset_pw_show1? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Enter new password" v-model="new_password">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!reset_pw_show1" @click="reset_pw_show1 = true">
                            <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="reset_pw_show1" @click="reset_pw_show1 = false">
                        </div>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center border border-gray-300">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                        </div>
                        <input :type="reset_pw_show2? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Confirm password" v-model="new_password2">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!reset_pw_show2" @click="reset_pw_show2 = true">
                            <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="reset_pw_show2" @click="reset_pw_show2 = false">
                        </div>
                    </div>
                </div>

                <div class="h-px-38 lh-1 flex-start-center">
                    <span class="text-danger fs-px-12" v-show="pw_match == false">
                        Your confirmation password does not match the new password
                    </span>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="d-flex w-100">
                <button class="py-3 fs-px-12 btn btn-transparent w-30" @click="GotoMove()">CANCEL</button>
                <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-70" @click="ResetPw()">Reset password</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'
import { BModal } from 'bootstrap-vue'
export default {
    components:{
        Header,Loading,BModal
    },
    data(){
        return{
            email: '',
            loading:false,
            show : true,
            // front ui
            resetPwModal: false,
            pw_show: false,
            new_password :"",
            new_password2 :'',
            reset_pw_show1: false,
            reset_pw_show2: false,
            pw_match: null,
            code : this.$route.params.code
            
        }
    },
    mounted(){
        this.resetPwModal = true;
    },
    methods:{
        GotoMove(){
            this.resetPwModal = false;
            this.$router.push({name: 'Signin'})

        },
        
        ResetPw(){
            const password = this.new_password;
            const password_confirm = this.new_password2;

            if(password ==password_confirm){
                this.ChangePassword()
            }else{
                this.pw_match = false;
            }
            // this.resetPwModal = false;

            // 비밀번호 매치하지 않을시
            // this.pw_match = false;
        },
        ChangePassword(){
            const code = this.code;
            const password = this.new_password;
            const body = {code,password};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/member/mailing/ChangePassword',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$pp({
                                msg: 'You have changed your password.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            this.$router.push(`/signin`)
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'Same as the old password.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }
                    }
                }   
            )
            
        },
    }

}
</script>