<template>
    <div>
        <Header :link="'hide'"/>

        <div class="px-3">
            <ul class="pt-3 pb-4 mb-2 d-flex text-center">
                <li class="rounded-start py-3 w-50 bg-yellow-4 text-gray-500 fw-bolder" @click="$router.push('/signin')">LOG IN</li>
                <li class="rounded-end py-3 fw-bold w-50 bg-yellow-1 fw-bold"  @click="$router.push('/signup')">SIGN UP</li>
            </ul>

            <div class="mb-4">
                <div class="rounded-1 h-px-48 bg-white flex-between-center mb-3">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/email.svg" alt="email" width="30">
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your email" v-model="email">
                </div>

                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                    </div>
                    <input :type="pw_show? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your password" v-model="password">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!pw_show" @click="pw_show = true">
                        <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="pw_show" @click="pw_show = false">
                    </div>
                </div>

                <div class="py-3 gap"></div>
                
                <div class="rounded-1 h-px-48 bg-white flex-between-center mb-3 position-relative">
                    <input type="text" readonly class="form-select border-0 h-100 fs-px-12 text-capitalize" placeholder="Select your region" v-model="region" @click="region_pp=true">
                    <div class="dropdown_box bg-white rounded-1 mt-1 position-absolute top-100 start-0 w-100 p-3 shadow" v-if="region_pp">
                        <div class="rounded-1 bg-white flex-between-center mb-3 border">
                            <i class="far fa-search ps-2"></i>
                            <input type="text" class="form-control border-0 py-2" placeholder="Search" v-model="region_search">
                        </div>
                        <div class="dropdown_scroll mt-3">
                            <ul class="text-capitalize fs-px-13">
                                <li class="p-2" v-for="(item,index) in filtered_region_list" :key="index" :value="item.code" @click="SelectRegion(item)">{{item.title}}</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <!-- <div class="rounded-1 h-px-48 bg-white flex-between-center mb-3">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/ticket.svg" width="30">
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12" placeholder="Promo Code (Optional)" v-model="promo_code">
                    <div>
                        <button class="btn bg-burgundy-1 me-2 text-white fs-px-12 rounded-2" v-if="!chked_promo_code" @click="CheckRefferCode()" :disabled="promo_code == ''">Check</button>
                        <span class="me-2" v-else>
                            <img src="@/assets/img/layout_icon/chk_circle.svg" alt="checked" width="30">
                        </span>
                    </div>
                </div> -->
            </div>

            <div class="mb-4">
                <p class="text-white mb-2">Date of Birth</p>

                <div class="flex-between-center">
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100 me-3">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_day">
                            <option value="">Day</option>
                            <option v-for="(item,index) in day_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100 me-3">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_month">
                            <option value="">Month</option>
                            <option v-for="(item,index) in month_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_year">
                            <option value="">Year</option>
                            <option v-for="(item,index) in year_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="text-white fs-px-12 mb-4">
                <div class="form-check d-flex mb-3">
                    <input class="form-check-input square flex-shrink-0" type="checkbox" id="terms_chk1" v-model="terms_chk1">
                    <label class="form-check-label ms-2" for="terms_chk1">
                        By entering the site, I confirm that I am 18 years old or more and I have read the <span class="text-decoration-underline" @click.stop.prevent="TermsPopupOn(0)">Terms of Service</span>
                    </label>
                </div>
                <div class="form-check d-flex">
                    <input class="form-check-input square flex-shrink-0" type="checkbox" id="terms_chk2" v-model="terms_chk2">
                    <label class="form-check-label ms-2" for="terms_chk2">
                        Receive Promotion Emails (Optional)
                    </label>
                </div>
            </div>

            <div>
                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="Register()">
                    Register Account
                </button>
            </div>
        </div>

        <TermsPopup ref="TermsPopup" />

        <Loading v-if="loading"/>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import { BModal } from 'bootstrap-vue'
import TermsPopup from '@/components/terms/Terms.vue'
import Loading from '@/components/common/Loading.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        Header, BModal, TermsPopup,Loading
    },
    data(){
        return{
            email: '',
            password: '',
            region: '',
            promo_code: '',
            birth_day: '',
            birth_month: '',
            birth_year: '',

            terms_chk1: false,
            terms_chk2: false,
            loading : false,
            region_list: [
                {
                    code: 'ad', 
                    title: 'andorra',
                },
                {
                    code: 'ae', 
                    title: 'united arab emirates',
                },
                {
                    code: 'af', 
                    title: 'afghanistan',
                },
                {
                    code: 'ag', 
                    title: 'antigua and barbuda',
                },
                {
                    code: 'ai', 
                    title: 'anguilla',
                },
                {
                    code: 'al', 
                    title: 'albania',
                },
                {
                    code: 'am', 
                    title: 'armenia',
                },
                {
                    code: 'an', 
                    title: 'netherlands antilles',
                },
                {
                    code: 'ao', 
                    title: 'angola',
                },
                {
                    code: 'aq', 
                    title: 'antarctica',
                },
                {
                    code: 'ar', 
                    title: 'argentina',
                },
                {
                    code: 'as', 
                    title: 'american samoa',
                },
                {
                    code: 'at', 
                    title: 'austria',
                },
                {
                    code: 'au', 
                    title: 'australia',
                },
                {
                    code: 'aw', 
                    title: 'aruba',
                },
                {
                    code: 'ax', 
                    title: 'aland islands',
                },
                {
                    code: 'az', 
                    title: 'azerbaijan',
                },
                {
                    code: 'ba', 
                    title: 'bosnia hercegovina',
                },
                {
                    code: 'bb', 
                    title: 'barbados',
                },
                {
                    code: 'bd', 
                    title: 'bangladesh',
                },
                {
                    code: 'be', 
                    title: 'belgium',
                },
                {
                    code: 'bf', 
                    title: 'burkina faso',
                },
                {
                    code: 'bg', 
                    title: 'bulgaria',
                },
                {
                    code: 'bh', 
                    title: 'bahrain',
                },
                {
                    code: 'bi', 
                    title: 'burundi',
                },
                {
                    code: 'bj', 
                    title: 'benin',
                },
                {
                    code: 'bl', 
                    title: 'saint barthelemy',
                },
                {
                    code: 'bm', 
                    title: 'bermuda',
                },
                {
                    code: 'bn', 
                    title: 'brunei darussalam',
                },
                {
                    code: 'bo', 
                    title: 'bolivia',
                },
                {
                    code: 'bq', 
                    title: 'bonaire',
                },
                {
                    code: 'br', 
                    title: 'brazil',
                },
                {
                    code: 'bs', 
                    title: 'bahamas',
                },
                {
                    code: 'bt', 
                    title: 'bhutan',
                },
                {
                    code: 'bv', 
                    title: 'bouvet island',
                },
                {
                    code: 'bw', 
                    title: 'botswana',
                },
                {
                    code: 'by', 
                    title: 'belarus',
                },
                {
                    code: 'bz', 
                    title: 'belize',
                },
                {
                    code: 'ca', 
                    title: 'canada',
                },
                {
                    code: 'cc', 
                    title: 'cocos (keeling) islands',
                },
                {
                    code: 'cd', 
                    title: 'democratic republic of the congo',
                },
                {
                    code: 'cf', 
                    title: 'central african republic',
                },
                {
                    code: 'cg', 
                    title: 'congo',
                },
                {
                    code: 'ch', 
                    title: 'switzerland',
                },
                {
                    code: 'ci', 
                    title: 'cote d`ivoire',
                },
                {
                    code: 'ck', 
                    title: 'cook islands',
                },
                {
                    code: 'cl', 
                    title: 'chile',
                },
                {
                    code: 'cm', 
                    title: 'cameroon',
                },
                {
                    code: 'cn', 
                    title: 'china',
                },
                {
                    code: 'co', 
                    title: 'colombia',
                },
                {
                    code: 'cr', 
                    title: 'costa rica',
                },
                {
                    code: 'cu', 
                    title: 'cuba',
                },
                {
                    code: 'cv', 
                    title: 'cape verde',
                },
                {
                    code: 'cw', 
                    title: 'curacao',
                },
                {
                    code: 'cx', 
                    title: 'christmas island',
                },
                {
                    code: 'cy', 
                    title: 'cyprus',
                },
                {
                    code: 'cz', 
                    title: 'czech republic',
                },
                {
                    code: 'de', 
                    title: 'germany',
                },
                {
                    code: 'dj', 
                    title: 'djibouti',
                },
                {
                    code: 'dk', 
                    title: 'denmark',
                },
                {
                    code: 'dm', 
                    title: 'dominica',
                },
                {
                    code: 'do', 
                    title: 'dominican republic',
                },
                {
                    code: 'dz', 
                    title: 'algeria',
                },
                {
                    code: 'ec', 
                    title: 'ecuador',
                },
                {
                    code: 'ee', 
                    title: 'estonia',
                },
                {
                    code: 'eg', 
                    title: 'egypt',
                },
                {
                    code: 'eh', 
                    title: 'western sahara',
                },
                {
                    code: 'er', 
                    title: 'eritrea',
                },
                {
                    code: 'es', 
                    title: 'spain',
                },
                {
                    code: 'et', 
                    title: 'ethiopia',
                },
                {
                    code: 'fi', 
                    title: 'finland',
                },
                {
                    code: 'fj', 
                    title: 'fiji',
                },
                {
                    code: 'fk', 
                    title: 'falkland islands',
                },
                {
                    code: 'fm', 
                    title: 'micronesia',
                },
                {
                    code: 'fo', 
                    title: 'faroe islands',
                },
                {
                    code: 'fr', 
                    title: 'france',
                },
                {
                    code: 'ga', 
                    title: 'gabon',
                },
                {
                    code: 'gb', 
                    title: 'united kingdom',
                },
                {
                    code: 'gd', 
                    title: 'grenada',
                },
                {
                    code: 'ge', 
                    title: 'georgia',
                },
                {
                    code: 'gf', 
                    title: 'french guiana',
                },
                {
                    code: 'gg', 
                    title: 'guernsey',
                },
                {
                    code: 'gh', 
                    title: 'ghana',
                },
                {
                    code: 'gi', 
                    title: 'gibraltar',
                },
                {
                    code: 'gl', 
                    title: 'greenland',
                },
                {
                    code: 'gm', 
                    title: 'gambia',
                },
                {
                    code: 'gn', 
                    title: 'guinea',
                },
                {
                    code: 'gp', 
                    title: 'guadeloupe',
                },
                {
                    code: 'gq', 
                    title: 'equatorial guinea',
                },
                {
                    code: 'gr', 
                    title: 'greece',
                },
                {
                    code: 'gs', 
                    title: 'south georgia and south sandwich lslands',
                },
                {
                    code: 'gt', 
                    title: 'guatemala',
                },
                {
                    code: 'gu', 
                    title: 'guam',
                },
                {
                    code: 'gw', 
                    title: 'guinea-bissau',
                },
                {
                    code: 'gy', 
                    title: 'guyana',
                },
                {
                    code: 'hk', 
                    title: 'hong kong',
                },
                {
                    code: 'hm', 
                    title: 'heard and mcdonald islands',
                },
                {
                    code: 'hn', 
                    title: 'honduras',
                },
                {
                    code: 'hr', 
                    title: 'croatia',
                },
                {
                    code: 'ht', 
                    title: 'haiti',
                },
                {
                    code: 'hu', 
                    title: 'hungary',
                },
                {
                    code: 'hw', 
                    title: 'hawaii',
                },
                {
                    code: 'id', 
                    title: 'indonesia',
                },
                {
                    code: 'ie', 
                    title: 'ireland',
                },
                {
                    code: 'il', 
                    title: 'israel',
                },
                {
                    code: 'im', 
                    title: 'isle of man',
                },
                {
                    code: 'in', 
                    title: 'india',
                },
                {
                    code: 'io', 
                    title: 'british indian ocean territory',
                },
                {
                    code: 'iq', 
                    title: 'iraq',
                },
                {
                    code: 'ir', 
                    title: 'iran',
                },
                {
                    code: 'is', 
                    title: 'iceland',
                },
                {
                    code: 'it', 
                    title: 'italy',
                },
                {
                    code: 'je', 
                    title: 'jersey',
                },
                {
                    code: 'jm', 
                    title: 'jamaica',
                },
                {
                    code: 'jo', 
                    title: 'jordan',
                },
                {
                    code: 'jp', 
                    title: 'japan',
                },
                {
                    code: 'ke', 
                    title: 'kenya',
                },
                {
                    code: 'kg', 
                    title: 'kyrgyzstan',
                },
                {
                    code: 'kh', 
                    title: 'cambodia',
                },
                {
                    code: 'ki', 
                    title: 'kiribati',
                },
                {
                    code: 'km', 
                    title: 'comoros',
                },
                {
                    code: 'kn', 
                    title: 'saint kitts and nevis',
                },
                {
                    code: 'kp', 
                    title: 'north korea',
                },
                {
                    code: 'kr', 
                    title: 'south korea',
                },
                {
                    code: 'kv', 
                    title: 'kosovo',
                },
                {
                    code: 'kw', 
                    title: 'kuwait',
                },
                {
                    code: 'ky', 
                    title: 'cayman islands',
                },
                {
                    code: 'kz', 
                    title: 'kazakhstan',
                },
                {
                    code: 'la', 
                    title: 'laos',
                },
                {
                    code: 'lb', 
                    title: 'lebanon',
                },
                {
                    code: 'lc', 
                    title: 'saint lucia',
                },
                {
                    code: 'li', 
                    title: 'liechtenstein',
                },
                {
                    code: 'lk', 
                    title: 'sri lanka',
                },
                {
                    code: 'lr', 
                    title: 'liberia',
                },
                {
                    code: 'ls', 
                    title: 'lesotho',
                },
                {
                    code: 'lt', 
                    title: 'lithuania',
                },
                {
                    code: 'lu', 
                    title: 'luxembourg',
                },
                {
                    code: 'lv', 
                    title: 'latvia',
                },
                {
                    code: 'ly', 
                    title: 'libyan arab jamahiriya',
                },
                {
                    code: 'ma', 
                    title: 'morocco',
                },
                {
                    code: 'mc', 
                    title: 'monaco',
                },
                {
                    code: 'md', 
                    title: 'moldova',
                },
                {
                    code: 'me', 
                    title: 'montenegro',
                },
                {
                    code: 'mf', 
                    title: 'saint martin (french part)',
                },
                {
                    code: 'mg', 
                    title: 'madagascar',
                },
                {
                    code: 'mh', 
                    title: 'marshall islands',
                },
                {
                    code: 'mk', 
                    title: 'macedonia',
                },
                {
                    code: 'ml', 
                    title: 'mali',
                },
                {
                    code: 'mm', 
                    title: 'myanmar',
                },
                {
                    code: 'mn', 
                    title: 'mongolia',
                },
                {
                    code: 'mo', 
                    title: 'macau',
                },
                {
                    code: 'mp', 
                    title: 'northern mariana islands',
                },
                {
                    code: 'mq', 
                    title: 'martinique',
                },
                {
                    code: 'mr', 
                    title: 'mauritania',
                },
                {
                    code: 'ms', 
                    title: 'montserrat',
                },
                {
                    code: 'mt', 
                    title: 'malta',
                },
                {
                    code: 'mu', 
                    title: 'mauritius',
                },
                {
                    code: 'mv', 
                    title: 'maldives',
                },
                {
                    code: 'mw', 
                    title: 'malawi',
                },
                {
                    code: 'mx', 
                    title: 'mexico',
                },
                {
                    code: 'my', 
                    title: 'malaysia',
                },
                {
                    code: 'mz', 
                    title: 'mozambique',
                },
                {
                    code: 'na', 
                    title: 'namibia',
                },
                {
                    code: 'nc', 
                    title: 'new caledonia',
                },
                {
                    code: 'ne', 
                    title: 'niger',
                },
                {
                    code: 'nf', 
                    title: 'norfolk island',
                },
                {
                    code: 'ng', 
                    title: 'nigeria',
                },
                {
                    code: 'ni', 
                    title: 'nicaragua',
                },
                {
                    code: 'nl', 
                    title: 'netherlands',
                },
                {
                    code: 'no', 
                    title: 'norway',
                },
                {
                    code: 'np', 
                    title: 'nepal',
                },
                {
                    code: 'nr', 
                    title: 'nauru',
                },
                {
                    code: 'nt', 
                    title: 'neutral zone',
                },
                {
                    code: 'nu', 
                    title: 'niue',
                },
                {
                    code: 'nz', 
                    title: 'new zealand',
                },
                {
                    code: 'om', 
                    title: 'oman',
                },
                {
                    code: 'pa', 
                    title: 'panama',
                },
                {
                    code: 'pe', 
                    title: 'peru',
                },
                {
                    code: 'pf', 
                    title: 'french polynesia',
                },
                {
                    code: 'pg', 
                    title: 'papua new guinea',
                },
                {
                    code: 'ph', 
                    title: 'philippines',
                },
                {
                    code: 'pk', 
                    title: 'pakistan',
                },
                {
                    code: 'pl', 
                    title: 'poland',
                },
                {
                    code: 'pm', 
                    title: 'saint pierre and miquelon',
                },
                {
                    code: 'pn', 
                    title: 'pitcairn',
                },
                {
                    code: 'pr', 
                    title: 'puerto rico',
                },
                {
                    code: 'ps', 
                    title: 'palestine',
                },
                {
                    code: 'pt', 
                    title: 'portugal',
                },
                {
                    code: 'pw', 
                    title: 'palau',
                },
                {
                    code: 'py', 
                    title: 'paraguay',
                },
                {
                    code: 'qa', 
                    title: 'qatar',
                },
                {
                    code: 're', 
                    title: 'reunion',
                },
                {
                    code: 'ro', 
                    title: 'romania',
                },
                {
                    code: 'rs', 
                    title: 'serbia',
                },
                {
                    code: 'ru', 
                    title: 'russian federation',
                },
                {
                    code: 'rw', 
                    title: 'rwanda',
                },
                {
                    code: 'sa', 
                    title: 'saudi arabia',
                },
                {
                    code: 'sb', 
                    title: 'solomon islands',
                },
                {
                    code: 'sc', 
                    title: 'seychelles',
                },
                {
                    code: 'sd', 
                    title: 'sudan',
                },
                {
                    code: 'se', 
                    title: 'sweden',
                },
                {
                    code: 'sg', 
                    title: 'singapore',
                },
                {
                    code: 'sh', 
                    title: 'saint helena',
                },
                {
                    code: 'si', 
                    title: 'slovenia',
                },
                {
                    code: 'sj', 
                    title: 'svalbard and jan mayen islands',
                },
                {
                    code: 'sk', 
                    title: 'slovakia',
                },
                {
                    code: 'sl', 
                    title: 'sierra leone',
                },
                {
                    code: 'sm', 
                    title: 'san marino',
                },
                {
                    code: 'sn', 
                    title: 'senegal',
                },
                {
                    code: 'so', 
                    title: 'somalia',
                },
                {
                    code: 'sr', 
                    title: 'suriname',
                },
                {
                    code: 'ss', 
                    title: 'south sudan',
                },
                {
                    code: 'st', 
                    title: 'sao tome and principe',
                },
                {
                    code: 'sv', 
                    title: 'el salvador',
                },
                {
                    code: 'sx', 
                    title: 'sint maarten (dutch part)',
                },
                {
                    code: 'sy', 
                    title: 'syrian arab republic',
                },
                {
                    code: 'sz', 
                    title: 'swaziland',
                },
                {
                    code: 'tc', 
                    title: 'turks and caicos islands',
                },
                {
                    code: 'td', 
                    title: 'chad',
                },
                {
                    code: 'tf', 
                    title: 'french southern territories',
                },
                {
                    code: 'tg', 
                    title: 'togo',
                },
                {
                    code: 'th', 
                    title: 'thailand',
                },
                {
                    code: 'tj', 
                    title: 'tajikistan',
                },
                {
                    code: 'tk', 
                    title: 'tokelau',
                },
                {
                    code: 'tl', 
                    title: 'east timor',
                },
                {
                    code: 'tm', 
                    title: 'turkmenistan',
                },
                {
                    code: 'tn', 
                    title: 'tunisia',
                },
                {
                    code: 'to', 
                    title: 'tonga',
                },
                {
                    code: 'tr', 
                    title: 'turkey',
                },
                {
                    code: 'tt', 
                    title: 'trinidad and tobago',
                },
                {
                    code: 'tv', 
                    title: 'tuvalu',
                },
                {
                    code: 'tw', 
                    title: 'taiwan',
                },
                {
                    code: 'tz', 
                    title: 'tanzania',
                },
                {
                    code: 'ua', 
                    title: 'ukraine',
                },
                {
                    code: 'ug', 
                    title: 'uganda',
                },
                {
                    code: 'um', 
                    title: 'united states minor outlying islands',
                },
                {
                    code: 'us', 
                    title: 'united states',
                },
                {
                    code: 'uy', 
                    title: 'uruguay',
                },
                {
                    code: 'uz', 
                    title: 'uzbekistan',
                },
                {
                    code: 'va', 
                    title: 'vatican city state',
                },
                {
                    code: 'vc', 
                    title: 'saint vincent and the grenadines',
                },
                {
                    code: 've', 
                    title: 'venezuela',
                },
                {
                    code: 'vg', 
                    title: 'virgin islands (british)',
                },
                {
                    code: 'vi', 
                    title: 'virgin islands (u.s.)',
                },
                {
                    code: 'vn', 
                    title: 'viet nam',
                },
                {
                    code: 'vu', 
                    title: 'vanuatu',
                },
                {
                    code: 'wf', 
                    title: 'wallis and futuna islands',
                },
                {
                    code: 'ws', 
                    title: 'samoa',
                },
                {
                    code: 'ye', 
                    title: 'yemen',
                },
                {
                    code: 'yt', 
                    title: 'mayotte',
                },
                {
                    code: 'yu', 
                    title: 'yugoslavia',
                },
                {
                    code: 'za', 
                    title: 'south africa',
                },
                {
                    code: 'zm', 
                    title: 'zambia',
                },
                {
                    code: 'zr', 
                    title: 'zaire',
                },
                {
                    code: 'zw', 
                    title: 'zimbabwe',
                }
            ],

            // front ui
            pw_show: false,
            
            today: new Date(),
            year_list: [],
            month_list: [],
            day_list: [],
            region_code : '',
            region_pp: false,
            region_search: '',

            chked_promo_code: false,
        }
    },
    computed:{
        filtered_region_list() {
            const searchText = this.region_search.trim();
            
            if (!searchText) {
                return this.region_list;
            }
            
            const regex = new RegExp(`${searchText}`, 'i');
            return this.region_list
                .filter(item => regex.test(item.title))
                .sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));
        }
    },
    watch:{
        promo_code(){
            this.chked_promo_code = false
        }
    },
    methods:{
        Register(){
            let result = true;

            if( this.email == '' ){
                this.$pp({
                    msg: 'Please enter a valid email address', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return;
            }
            
            let regex = new RegExp('[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}');

            const test = regex.test(this.email);

            if(!test){
                this.$pp({
                    msg: 'Please check your email format', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return false;
            }

            if( this.password == '' ){
                this.$pp({
                    msg: 'Please enter a password', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return false;
            }else if( this.region == '' ){
                this.$pp({
                    msg: 'Please select a region', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return false
            }else if( this.terms_chk1 == false ){
                this.$pp({
                    msg: 'Please check agree to terms', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return false
            }else if( this.ChkBirth() == false ){
                result = false;
                return false;
            }else if( this.promo_code != '' && !this.chked_promo_code ){
                this.$pp({
                    msg: 'You have not checked Referral Code’s availability, please check availability moving forward.',
                    auto_hide:true
                })
                return false;
            }
             


            const email = this.email;
            const password = this.password;
            const region = this.region_code;
            const reffer = this.promo_code;
            const birth_year = this.birth_year;
            const birth_day = this.birth_day;
            const birth_month = this.birth_month;


            const body = {email,password,region,reffer,birth_year,birth_month,birth_day};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            
            if( result == true ){
                this.loading = true;
                this.$http.post('/member/sign/sign',{req}).then(
                    (res) =>  {
                        if(res.status == 200){
                            this.loading = false;
                            if(res.data.code =="200"){
                                this.$pp({
                                    msg: 'A verification link has been sent to the email you entered.', 
                                    is_confirm: false, 
                                    auto_hide:false
                                })
                                const link = res.data.info;
                                this.$router.push({path:link})
                                return false;
                                
                            }else if(res.data.code =="100"){
                                this.$pp({
                                    msg: 'This email is in use.', 
                                    is_confirm: false, 
                                    auto_hide:true
                                })
                                return false;
                            }else if(res.data.code =="110"){
                                this.$pp({
                                    msg: 'This email address needs to be verified. Please verifiy your email address.', 
                                    is_confirm: false, 
                                    auto_hide:true
                                })
                                this.$router.push({path:'/confirm-email'});
                                return false;
                            }else if(res.data.code =="300"){
                                this.$pp({
                                    msg: 'RefferCode Check', 
                                    is_confirm: false, 
                                    auto_hide:true
                                })
                                return false;
                            }
                        }
                    }   
                )
                // 
            }

        },
        ChkBirth(){
            if( this.birth_day != '' || this.birth_month != '' || this.birth_year != '' ){
                let valid_result = true;

                var y = this.birth_year;
                var m = this.birth_month - 1;
                var d = this.birth_day;

                var date = new Date(y, m, d);

                if (date.getFullYear() == y && date.getMonth() == m && date.getDate() == d) {
                    valid_result = true;
                }
                else {
                    valid_result = false;
                }

                if( valid_result == false ){
                    this.$pp({
                        msg: 'Invalid date of birth', 
                        is_confirm: false, 
                        auto_hide:true
                    })
                    return false;

                }else{
                    const currentDate = new Date();
                    const minAgeDate = new Date(
                        currentDate.getFullYear() - 18,
                        currentDate.getMonth(),
                        currentDate.getDate()
                    );
                    
                    if( date > minAgeDate ){
                        this.$pp({
                            msg: 'You must be at least 18 years old to use OCB service.', 
                            is_confirm: false, 
                            auto_hide:true
                        })
                        return false;
                    }else{
                        return true;
                    }
                }

            }else{
                return true;
            }
        },
        SelectRegion(item){
            this.region = item.title;
            this.region_code = item.code;
            this.region_pp = false;
            this.region_search = '';
        },
        CheckRefferCode(){
            const reffer = this.promo_code;
            const body = {reffer};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/sign/CheckRefferCode',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.chked_promo_code = true;
                            this.$pp({
                                msg: 'Valid!',
                                auto_hide:true
                            })

                            
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'Invalid Code',
                                auto_hide:true
                            })
                            return false;
                        }
                    }
                }   
            )
        },
        TermsPopupOn(idx){
            this.$refs.TermsPopup.idx = idx;
            this.$refs.TermsPopup.pp = true;
        }
    },
    mounted(){
        let today_year = this.today.getFullYear();
        let year_list = [];
        let month_list = [];
        let day_list = [];

        for(let i=1900; i<=today_year; i++){
            year_list.push(i);
        }
        for(let i=1; i<=12; i++){
            month_list.push(i);
        }
        for(let i=1; i<=31; i++){
            day_list.push(i);
        }

        this.year_list = year_list;
        this.month_list = month_list;
        this.day_list = day_list;

        if(this.$route.params.code !=undefined){
            this.promo_code = this.$route.params.code;

            this.CheckRefferCode();
        }
        
    }

}
</script>
<style lang="scss" scoped>
    .dropdown_box{
        max-height: 230px;
        overflow: hidden;

        .dropdown_scroll{
            overflow: auto;
            max-height: calc(260px - 120px);
        }
    }
</style>








